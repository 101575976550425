@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
        .masonry-3-col {
            column-count: 3;
            column-gap: 1em;
        }
        .masonry-2-col {
            column-count: 2;
            column-gap: 1em;
        }
        .break-inside {
            break-inside: avoid;
        }
    }
}

@layer compontents {

    .navbar-item {
        @apply block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 font-mono;
    }
    .app-icon {
        @apply object-cover w-48 h-48 rounded-3xl;
    }
    .hero-container {
        @apply p-1 md:p-40 h-auto w-fit items-center md:w-auto font-mono dark:text-white;
    }
    .dark-theme {
        @apply dark:bg-gray-700 dark:text-white;
    }
    .subtitle {
        @apply text-sm text-gray-500 dark:text-gray-400;
    }

}